import React, {Component} from "react";
import {Button, List, ListItem, ListItemText, Menu, MenuItem, TextField} from "@material-ui/core";

class AmountCalculation extends Component {

    constructor(props) {
        super(props);
        let chip = props.chip;
        let minMaxAmount = chip.minMaxAmount;
        this.state = {
            minimum: minMaxAmount.minimum,
            maximum: minMaxAmount.maximum,
            typeOfMeasurement: minMaxAmount.typeOfMeasurement ? minMaxAmount.typeOfMeasurement : "oz",
            anchorEl: null
        };
    }

    save() {
        this.props.save({
            minimum: this.state.minimum ? this.state.minimum : null,
            maximum: this.state.maximum ? this.state.maximum : null,
            typeOfMeasurement: this.state.typeOfMeasurement,
            __typename: "minMaxAmount"
        });
    }

    render() {
        return <div style={{padding: 10}}>
            {"Select a minimum and maximum amount of the ingredient below."}
            <div className="flex-row-space-evenly">
                <TextField
                    type="number"
                    defaultValue={this.state.minimum}
                    label="Minimum"
                    color="secondary"
                    onChange={(event) => {
                        let newValue = event.target.value;
                        this.setState({
                            minimum: newValue
                        });
                    }}
                />
                <TextField
                    type="number"
                    label="Maximum"
                    defaultValue={this.state.maximum}
                    color="secondary"
                    onChange={(event) => {
                        let newValue = event.target.value;
                        this.setState({
                            maximum: newValue
                        });
                    }}
                />
                <List component="nav">
                    <ListItem button onClick={(event) => {
                        this.setState({
                            anchorEl: event.currentTarget
                        });
                    }}>
                        <ListItemText primary={this.state.typeOfMeasurement}/>
                    </ListItem>
                    <Menu keepMounted
                          anchorEl={this.state.anchorEl}
                          open={this.state.anchorEl !== null}
                          onClose={() => {
                              this.setState({anchorEl: null});
                          }}
                    >
                        <MenuItem key={"menuitem-oz"} value={"oz"} onClick={() => {
                            this.setState({typeOfMeasurement: "oz", anchorEl: null});
                        }}>{"oz"}</MenuItem>
                        <MenuItem key={"menuitem-ml"} value={"ml"} onClick={() => {
                            this.setState({typeOfMeasurement: "ml", anchorEl: null});
                        }}>{"ml"}</MenuItem>
                    </Menu>
                </List>
            </div>
            <div className="filter-buttons">
                <Button
                    variant="outlined"
                    onClick={() => {
                        this.props.cancel();
                    }}
                    style={{marginRight: "10px"}}
                    color="secondary"
                >
                    {"Cancel"}
                </Button>
                <Button
                    variant="contained"
                    onClick={this.save.bind(this)}
                    color="secondary"
                    style={{color: "white"}}
                >
                    {"Search"}
                </Button>
            </div>
        </div>;
    }

}

export default AmountCalculation;
