import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_BOOK_NAME} from "../utilities/Queries";
import RecipesInner from "./RecipesInner";
import {useLocation} from "react-router-dom";
import {client} from "../ApolloLayer";


export default function RecipesOuter(props) {
    let bookTitle = undefined;
    let location = useLocation();
    let pathName = location.pathname;
    let split = pathName.split("/");
    let bookAbbreviation = split[2];
    const {loading: getBookNameLoading, error: getBookNameError, data} = useQuery(GET_BOOK_NAME, {
        variables: {book: bookAbbreviation},
        client: client
    });
    if (getBookNameLoading || getBookNameError) {
        return <div/>;
    }
    bookTitle = data.book.name;

    const {searchTerms, selectedGlasses, selectedBuildTypes, selectedTypes, selectedCocktailCategories, selectedBooks, minMaxIngredients, minMaxABV, minMaxAlcoholicUnits} = props;

    return <RecipesInner bookTitle={bookTitle}
                         isLoggedIn={this.props.isLoggedIn}
                         searchTerms={searchTerms}
                         selectedGlasses={selectedGlasses}
                         selectedBuildTypes={selectedBuildTypes}
                         selectedTypes={selectedTypes}
                         selectedCocktailCategories={selectedCocktailCategories}
                         selectedBooks={selectedBooks}
                         minMaxIngredients={minMaxIngredients}
                         minMaxABV={minMaxABV}
                         minMaxAlcoholicUnits={minMaxAlcoholicUnits}/>;
}