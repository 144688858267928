import React from "react";
import ListItem from "@material-ui/core/ListItem";
import {ListItemText} from "@material-ui/core";
import {Link} from "react-router-dom";

export default function CocktailMenuOverviewItem(props) {
    let menu = props.menu;
    return <Link to={"/cocktailmenu/" + menu.id} style={{textDecoration: "none", color: "inherit"}}>
        <ListItem button>
            <ListItemText primary={menu.name} secondary={menu.description}/>
        </ListItem>
    </Link>;
}