import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, List, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import SingleIngredient from "./SingleIngredient";

export default function ExpandableIngredients(props) {
    const [expanded, setExpanded] = React.useState(false);
    const [search, setSearch] = React.useState("");

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const categories = props.categories;
    let filteredCategories = {};
    Object.keys(categories).forEach((category) => {
        let list = categories[category];
        list = list.filter((ingredient) => {
            let lowerCaseIngredient = ingredient.name.toLowerCase();
            let split = lowerCaseIngredient.split(", ");
            return (lowerCaseIngredient.startsWith(search) || (split[1] && split[1].startsWith(search)) || (split[1] && (split[1] + " " + split[0]).startsWith(search)));
        });
        if (list.length > 0) {
            filteredCategories[category] = list;
        }
    });
    return (
        <div className="flex-column margin-above margin-below">
            <TextField color="secondary"
                       style={{margin: "10px"}}
                       label="Search"
                       onChange={(event) => {
                           setSearch(event.target.value.toLowerCase());
                       }}
                       variant="outlined"/>
            {Object.keys(filteredCategories).map(category => {
                let list = filteredCategories[category];

                let isExpanded = expanded === category;
                return <Accordion expanded={isExpanded} onChange={handleChange(category)} key={category}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                    >
                        <Typography>{category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isExpanded && <List style={{width: "100%"}}>
                            {list.map((ingredient) => {
                                return <SingleIngredient key={ingredient.id} ingredient={ingredient}/>;

                            })}
                        </List>}
                    </AccordionDetails>
                </Accordion>;
            })}

        </div>
    );
}