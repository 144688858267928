import React, {Component} from "react";
import GoogleLogin from "react-google-login";
import "./SignInWithGoogle.css";
import {Paper, Typography} from "@material-ui/core";

class SignInWithGoogle extends Component {

    responseGoogle = (response) => {
        if (response.googleId) {
            this.props.loginSuccessful(response.googleId);
        } else {
            console.log("Didn't get google id from google", response);
        }
    };

    render() {
        return <div className="outer">
            <Paper className="inner" elevation={4}>
                <div className="element">
                    <Typography variant="h5" component="h1">
                        Project Daiquiri
                    </Typography>
                </div>
                <div className="element">
                    <Typography component="p">
                        Welcome! Please login with Google to access the application.
                    </Typography>
                </div>
                <GoogleLogin clientId="51903684772-v3bmco4sa03h5i2tiee259vh7pv69phn.apps.googleusercontent.com"
                             buttonText="Login"
                             onSuccess={this.responseGoogle}
                             onFailure={this.responseGoogle}/>
            </Paper>
        </div>;
    }
}

export default SignInWithGoogle;
