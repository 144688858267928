import React from "react";
import "../CocktailMenu.css";
import {externalClient} from "./CocktailMenuExternalUserFetcher";
import {useLocation} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import {GET_COCKTAIL_MENU} from "../CocktailMenuQueries";
import CircularProgress from "@material-ui/core/CircularProgress";
import CocktailMenuRenderer from "../renderer/CocktailMenuRenderer";

export default function CocktailMenuExternalUserFetcher(props) {
    let location = useLocation();
    let pathName = location.pathname;
    let split = pathName.split("/");
    let cocktailMenuId = split[2];
    const {loading, error, data} = useQuery(GET_COCKTAIL_MENU, {
        variables: {id: cocktailMenuId},
        client: externalClient
    });
    if (loading) {
        return <CircularProgress color="secondary"/>;
    }
    if (error) {
        console.log(error)
        return <div>Oh no, an error occurred. We're sorry!</div>;
    }
    return <CocktailMenuRenderer menu={data.cocktailMenu}/>;
}