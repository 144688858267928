import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_ALL_INGREDIENTS} from "../../utilities/Queries";
import {CircularProgress} from "@material-ui/core";
import ExpandableIngredients from "./ExpandableIngredients";
import {client} from "../../ApolloLayer";

export default function IngredientsIndividualView(props) {
    const {loading, error, data} = useQuery(GET_ALL_INGREDIENTS, {
        client: client
    });

    if (loading) {
        return <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress color="secondary"/></div>
    }
    if (error) {
        return error;
    }
    let ingredients = data.ingredients.sort((ingredient1, ingredient2) => {
        return ingredient2.recipeCount.total - ingredient1.recipeCount.total;
    });
    let categories = {
        "Spirits": [],
        "Juices": [],
        "Liqueurs": [],
        "Syrups": [],
        "Bitters": [],
        "Wines": [],
        "Other": []
    };
    ingredients.forEach(ingredient => {
        let category = ingredient.name.split(",")[0];
        if (category === "Rum" || category === "Whiskey" || category === "Gin" || category === "Vodka"
            || category === "Tequila" || category === "Mezcal" || category === "Genever" || category === "Brandy"
            || category === "Aquavit" || category === "Arrack" || category === "Batavia Arrack"
            || category === "Cachaça" || category === "Eau de Vie" || category === "Moonshine"
            || category === "Sotol" || category === "AU CHOIX" || category === "Okolehao" || category === "Grappa") {
            categories["Spirits"].push(ingredient);
        } else if (category === "Juice" || category === "Purée") {
            categories["Juices"].push(ingredient);
        } else if (category === "Liqueur" || category === "Amaro") {
            categories["Liqueurs"].push(ingredient);
        } else if (category === "Syrup" || category === "Nectar") {
            categories["Syrups"].push(ingredient);
        } else if (category === "Bitters") {
            categories["Bitters"].push(ingredient);
        } else if (category === "Wine" || category === "Vermouth" || category === "Sherry"
            || category === "Lillet Blanc" || category === "Cocchi Americano" || category === "Lillet Rouge"
            || category === "Lillet rosé" || category === "Dubonnet") {
            categories["Wines"].push(ingredient);
        } else {
            categories["Other"].push(ingredient);
        }
    });
    return (
        <ExpandableIngredients categories={categories}/>
    );
}