import React from "react";
import {AppBar, Toolbar, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import {Menu} from "@material-ui/icons";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import {getFrontendUrl} from "./utilities/IOUtilities";

export default function TopBar(props) {
    let isLoggedIn = props.isLoggedIn;
    const isLargeScreen = useMediaQuery("(min-width:950px)");
    const [drawerOpen, setDrawerOpen] = React.useState(false);
   
    let menus = <Link to={"/menus"} style={{textDecoration: "none"}}>
        <Typography color="secondary" variant="h6" noWrap style={{marginRight: "10px"}}>
            {"Menus"}
        </Typography>
    </Link>;

    let recipes = <Link to={"/recipes"} style={{textDecoration: "none"}}>
        <Typography color="secondary" variant="h6" noWrap style={{marginRight: "10px"}}>
            {"Recipes"}
        </Typography>
    </Link>;

    let ingredients = <Link to={"/ingredients"} style={{textDecoration: "none"}}>
        <Typography color="secondary" variant="h6" noWrap style={{marginRight: "10px"}}>
            {"Ingredients"}
        </Typography>
    </Link>;

    let signOut = <Button color="secondary" onClick={() => {
        document.cookie = "googleId=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.href = getFrontendUrl();

    }}>
        {"Sign out"}
    </Button>;

    let signIn = <Link to={"/login"} style={{textDecoration: "none"}}>
        <Typography color="secondary" variant="h6" noWrap style={{marginRight: "10px"}}>
            {"Sign in"}
        </Typography>
    </Link>;

    return (
        <div className="header">
            <AppBar position="static">
                <Toolbar style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <Link to={"/"} style={{textDecoration: "none"}}>
                        <Typography color="secondary" variant="h5" noWrap>
                            {"Project Daiquiri"}
                        </Typography>
                    </Link>
                    {isLargeScreen ?
                        (isLoggedIn ?
                                <div className="flex-row-center">
                                    {recipes}
                                    {ingredients}
                                    {menus}
                                    {signOut}
                                </div> :
                                <div className="flex-row-center">
                                    {signIn}
                                </div>
                        ) :
                        <IconButton
                            color="secondary"
                            aria-label="open drawer"
                            onClick={() => {
                                setDrawerOpen(!drawerOpen);
                            }}
                        >
                            <Menu/>
                        </IconButton>
                    }
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                anchor="right"
                open={drawerOpen}
                onClose={() => {
                    setDrawerOpen(false);
                }}
            >
                <List>
                    {isLoggedIn ? <React.Fragment>
                            <ListItem button onClick={() => {
                                setDrawerOpen(false);
                            }} key={"recipes"}>
                                {recipes}
                            </ListItem>
                            <ListItem button onClick={() => {
                                setDrawerOpen(false);
                            }} key={"ingredients"}>
                                {ingredients}
                            </ListItem>
                            <ListItem button onClick={() => {
                                setDrawerOpen(false);
                            }} key={"Menus"}>
                                {menus}
                            </ListItem>
                            <ListItem button onClick={() => {
                                setDrawerOpen(false);
                            }} key={"Sign out"}>
                                {signOut}
                            </ListItem>
                        </React.Fragment> :
                        <ListItem button onClick={() => {
                            setDrawerOpen(false);
                        }} key={"Sign in"}>
                            {signIn}
                        </ListItem>}

                </List>
            </Drawer>
        </div>
    );
}