import React from "react";
import "../CocktailMenu.css";
import {ApolloProvider} from "react-apollo";
import {ApolloClient} from "apollo-client";
import {HttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import CocktailMenuExternalUserFetcherInner from "./CocktailMenuExternalUserFetcherInner";
import {getDefaultCacheData} from "../../ApolloLayer";
import {getServerUrl} from "../../utilities/IOUtilities";

const applicationCache = new InMemoryCache();
export let externalClient;
export default function CocktailMenuExternalUserFetcher(props) {
    externalClient = new ApolloClient({
        link: new HttpLink({uri: getServerUrl() + "/graphqlexternal"}),
        cache: applicationCache,
        resolvers: {}
    });
    applicationCache.writeData({
        data: getDefaultCacheData()
    });
    return <ApolloProvider client={externalClient}>
        <CocktailMenuExternalUserFetcherInner/>
    </ApolloProvider>;
}