import React, {useState} from "react";
import {Slider} from "@material-ui/core";
import {client} from "../../ApolloLayer";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    slider: {
        width: "60%"
    }
}));

export default function SliderFilterComponent(props) {
    const classes = useStyles();
    const [localValue, setLocalValue] = useState(props.value);
    const [isSliderActive, setIsSliderActive] = useState(false);
    if (!isSliderActive && (localValue[0] !== props.value[0] || localValue[1] !== props.value[1])) {
        setLocalValue(props.value);
    }
    return (
        <Slider
            className={classes.slider}
            color="secondary"
            value={localValue}
            step={props.step}
            onChange={(event, newValue) => {
                setLocalValue(newValue);
                setIsSliderActive(true);
            }}
            onChangeCommitted={(event, newValue) => {
                setIsSliderActive(false);
                let data = {};
                data[props.cacheName] = newValue;
                client.writeData({data: data});
            }}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            valueLabelFormat={(value, index) => {
                if (index === 1 && value === props.max) {
                    return value + "+";
                }
                return value;
            }}
            min={props.min}
            max={props.max}
            marks={props.marks}
        />);
}