import React, {useState} from "react";
import CocktailMenuOverviewItem from "./CocktailMenuOverviewItem";
import List from "@material-ui/core/List";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CREATE_COCKTAIL_MENU, GET_COCKTAIL_MENUS} from "../CocktailMenuQueries";
import {client} from "../../ApolloLayer";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

export default function CocktailMenus(props) {
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [newMenuText, setNewMenuText] = useState(null);

    const [createMenu] = useMutation(CREATE_COCKTAIL_MENU, {
        client: client
    });
    const {loading, error, data} = useQuery(GET_COCKTAIL_MENUS, {
        client: client
    });

    if (loading) {
        return <CircularProgress color="secondary"/>;
    }
    if (error) {
        return error;
    }
    let menus = data.cocktailMenus;
    return <div className="flex-column-center">
        <h1>{"Select a menu"}</h1>
        <List>
            {menus.map(menu => {
                return <CocktailMenuOverviewItem key={menu.id} menu={menu}/>;
            })}
            <ListItem button onClick={() => {
                setCreateDialogOpen(true);
            }}>
                <ListItemText>{"Create new cocktail menu"}</ListItemText>
            </ListItem>
        </List>
        <Dialog onClose={() => {
            setCreateDialogOpen(false);
        }} open={createDialogOpen}>
            <DialogContent>
                <TextField color="secondary"
                           style={{margin: "10px"}}
                           label="Menu name"
                           onChange={(event) => {
                               setNewMenuText(event.target.value);
                           }}
                           variant="outlined"/>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" disabled={!newMenuText || !newMenuText.trim()} onClick={() => {
                    setCreateDialogOpen(false);
                    createMenu({
                        variables: {"name": newMenuText},
                        refetchQueries: [{
                            query: GET_COCKTAIL_MENUS
                        }]
                    });
                }}>
                    {"Create"}
                </Button>
            </DialogActions>
        </Dialog>
    </div>;
}