import React, {Component} from "react";
import SignInWithGoogle from "./SignInWithGoogle";
import {getFrontendUrl, isLocalHost} from "./IOUtilities";
import {getGoogleIdCookie} from "./Utilities";

class LoginPage extends Component {

    constructor(props) {
        super(props);
        if (isLocalHost() && !getGoogleIdCookie()) {
            // googleId = "106324587349121787460"; // peterthorsentestbruger
            let googleId = "115587834512274835851"; // main
            document.cookie = this.getCookieString(googleId);
            window.location.href = getFrontendUrl();
        }
    }

    render() {
        return <SignInWithGoogle loginSuccessful={(googleId) => {
            document.cookie = this.getCookieString(googleId);
            window.location.href = getFrontendUrl();
        }} />;
    }

    getCookieString(googleId) {
        let CookieDate = new Date();
        CookieDate.setFullYear(CookieDate.getFullYear() + 10);
        return "googleId=" + googleId + "; expires=" + CookieDate.toUTCString() + ';';
    }
}

export default LoginPage;
