import React, {useState} from "react";
import "./CocktailMenu.css";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Draggable} from "react-beautiful-dnd";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {MoreVert} from "@material-ui/icons";
import {useMutation} from "@apollo/react-hooks";
import {DELETE_MENU_ITEM, EDIT_MENU_ITEM} from "./CocktailMenuQueries";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {DialogContent} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {client} from "../ApolloLayer";

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

export default function CocktailMenuSortItem(props) {
    let item = props.item;
    let index = props.index;
    let menu = props.menu;
    return <div>
        {renderListItem(item, index, menu)}

    </div>;
}

function renderListItem(item, index, menu) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [categoryText, setCategoryText] = useState(item.category);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [deleteItem] = useMutation(DELETE_MENU_ITEM, {
        client: client
    });
    const [editItem] = useMutation(EDIT_MENU_ITEM, {
        client: client
    });

    return <div><Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided, snapshot) => {
            return <ListItem
                // ContainerComponent="li"
                ContainerProps={{ref: provided.innerRef}}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                )}
            >
                <ListItemText
                    primary={item.name}
                    secondary={item.book.name}
                />
                <ListItemSecondaryAction>
                    <IconButton onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                    }}>
                        {!snapshot.isDragging && <MoreVert/>}
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>;
        }}</Draggable>
        <Menu keepMounted
              anchorEl={anchorEl}
              open={anchorEl !== null}
              onClose={() => {
                  setAnchorEl(null);
              }}
        >

            <MenuItem key={"menuitem-edit"} onClick={() => {
                setOpenEditDialog(true);
                setAnchorEl(null);
            }}>{"Edit"}</MenuItem>
            <MenuItem key={"menuitem-delete"} onClick={() => {
                deleteItem({
                    variables: {
                        menuId: menu.id,
                        id: item.id
                    }
                });
                setAnchorEl(null);
            }}>{"Delete"}</MenuItem>
        </Menu>
        <Dialog onClose={() => {
            setOpenEditDialog(false);
        }} open={openEditDialog}>
            <DialogContent>
                <TextField color="secondary"
                           style={{margin: "10px"}}
                           defaultValue={item.category}
                           label="Category"
                           onChange={(event) => {
                               setCategoryText(event.target.value);
                           }}
                           variant="outlined"/>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" disabled={!categoryText || !categoryText.trim()} onClick={() => {
                    setOpenEditDialog(false);
                    editItem({
                        variables: {
                            menuId: menu.id,
                            id: item.id,
                            category: categoryText
                        }
                    });
                }}>
                    {"Save"}
                </Button>
            </DialogActions>
        </Dialog>
    </div>;

}