import React, {Component} from "react";
import "./App.css";
import {getServerUrl} from "./utilities/IOUtilities";
import {getGoogleIdCookie} from "./utilities/Utilities";
import {ApolloProvider} from "react-apollo";
import {ApolloClient} from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory/lib/index";
import {HttpLink} from "apollo-link-http/lib/index";
import {
    MAX_ABV,
    MAX_ALCOHOLIC_UNITS,
    MAX_INGREDIENTS,
    MIN_ABV,
    MIN_ALCOHOLIC_UNITS,
    MIN_INGREDIENTS
} from "./recipes/search/AdvancedFilters";
import MainView from "./recipes/MainView";
import {Route, Switch} from "react-router-dom";
import FullRecipe from "./recipes/FullRecipe";
import TopBar from "./TopBar";
import Ingredients from "./ingredients/Ingredients";
import CocktailMenus from "./cocktailmenu/overview/CocktailMenus";
import CocktailMenuEditor from "./cocktailmenu/CocktailMenuEditor";
import LoginPage from "./utilities/LoginPage";

const applicationCache = new InMemoryCache();
export let client;

class ApolloLayer extends Component {

    render() {
        applicationCache.writeData({
            data: getDefaultCacheData()
        });
        if (!getGoogleIdCookie()) {
            client = new ApolloClient({
                link: new HttpLink({uri: getServerUrl() + "/graphqlexternal"}),
                cache: applicationCache,
                resolvers: {}
            });
            return <ApolloProvider client={client}>
                <div className="flex-column-center">
                    <TopBar isLoggedIn={false}/>
                    <Switch>
                        <Route exact path="/book/:s">
                            <MainView isBook={true} isLoggedIn={false}/>
                        </Route>
                        <Route path="/book/:s/">
                            <FullRecipe isLoggedIn={false}/>
                        </Route>
                        <Route exact path="/">
                            <MainView isBook={false} isLoggedIn={false}/>
                        </Route>
                        <Route exact path="/recipes">
                            <MainView isBook={false} isLoggedIn={false}/>
                        </Route>

                        <Route exact path="/login">
                            <LoginPage/>
                        </Route>
                        <Route path="*">
                            <div>This route doesn't exist, 404.</div>
                        </Route>
                    </Switch>
                </div>
            </ApolloProvider>;
        }
        let googleIdCookie = "googleId=" + getGoogleIdCookie();
        let uri = getServerUrl(googleIdCookie) + "/graphql?" + googleIdCookie;
        client = new ApolloClient({
            link: new HttpLink({uri: uri}),
            cache: applicationCache,
            resolvers: {}
        });

        return <ApolloProvider client={client}>
            <div className="flex-column-center">
                <TopBar isLoggedIn={true}/>
                <Switch>
                    <Route exact path="/book/:s">
                        <MainView isBook={true} isLoggedIn={true}/>
                    </Route>
                    <Route path="/book/:s/">
                        <FullRecipe isLoggedIn={true}/>
                    </Route>
                    <Route exact path="/">
                        <MainView isBook={false} isLoggedIn={true}/>
                    </Route>
                    <Route exact path="/menus">
                        <CocktailMenus/>
                    </Route>
                    <Route path="/CocktailMenu/:s/">
                        <CocktailMenuEditor/>
                    </Route>
                    <Route exact path="/recipes">
                        <MainView isBook={false} isLoggedIn={true}/>
                    </Route>
                    <Route exact path="/ingredients">
                        <Ingredients/>
                    </Route>
                    <Route exact path="/ingredients/recipes">
                        <Ingredients recipesView={true}/>
                    </Route>
                    <Route exact path="/ingredients/individually">
                        <Ingredients individualView={true}/>
                    </Route>
                    <Route path="*">
                        <div>This route doesn't exist, 404.</div>
                    </Route>
                </Switch>
            </div>
        </ApolloProvider>;
    }
}

export function getDefaultCacheData() {
    return {
        searchTerms: [],
        selectedGlasses: {
            __typename: "selectedGlasses",
            data: [],
            key: 0
        },
        selectedBuildTypes: {
            __typename: "selectedBuildTypes",
            data: [],
            key: 0
        },
        selectedTypes: {
            __typename: "selectedTypes",
            data: ["Cocktail"],
            key: 0
        },
        selectedCocktailCategories: {
            __typename: "selectedCocktailCategories",
            data: [],
            key: 0
        },
        selectedBooks: {
            __typename: "selectedBooks",
            data: [],
            key: 0
        },
        minMaxIngredients: [MIN_INGREDIENTS, MAX_INGREDIENTS],
        minMaxABV: [MIN_ABV, MAX_ABV],
        minMaxAlcoholicUnits: [MIN_ALCOHOLIC_UNITS, MAX_ALCOHOLIC_UNITS]
    };
}

export default ApolloLayer;
