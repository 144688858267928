import React from "react";
import {IconButton, ListItem, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import {CheckCircle, InfoOutlined} from "@material-ui/icons";
import {useMutation} from "@apollo/react-hooks";
import {GET_ALL_INGREDIENTS, INVERT_INGREDIENT} from "../../utilities/Queries";
import Divider from "@material-ui/core/Divider";
import {client} from "../../ApolloLayer";
import BottlesView from "./BottlesView";

export default function SingleIngredient(props) {
    const [displayPossibleBottles, setDisplayPossibleBottles] = React.useState(false);

    const [invertIngredient] = useMutation(INVERT_INGREDIENT, {
        client: client
    });

    let ingredient = props.ingredient;
    let listItemText;
    if (ingredient.owned) {
        listItemText = ingredient.name + " (Used in " + ingredient.recipeCount.total
            + " recipes and " + ingredient.recipeCount.canMake
            + " recipes you can make)";
    } else {
        listItemText = ingredient.name + " (Used in " + ingredient.recipeCount.total
            + " recipes and finishes " + ingredient.recipeCount.lastIngredientMissing
            + " recipes)";
    }
    return <React.Fragment>
        <ListItem>
            <ListItemText>{listItemText}</ListItemText>
            <ListItemSecondaryAction>
                {ingredient.hasBottles &&
                <IconButton edge="end" onClick={() => {
                    setDisplayPossibleBottles(ingredient);
                }} aria-label="delete">
                    <InfoOutlined/>
                </IconButton>}
                <IconButton edge="end" onClick={() => {
                    let invertedIngredient = {
                        __typename: "Ingredient",
                        id: ingredient.id,
                        owned: !ingredient.owned,
                        recipeCount: {
                            __typename: "RecipeCount",
                            total: ingredient.recipeCount.total,
                            canMake: ingredient.recipeCount.lastIngredientMissing,
                            lastIngredientMissing: ingredient.recipeCount.canMake
                        }

                    };
                    invertIngredient({
                        variables: {"ingredient": ingredient.name},
                        refetchQueries: [{
                            query: GET_ALL_INGREDIENTS
                        }],
                        optimisticResponse: {
                            __typename: "Mutation",
                            invertIngredient: invertedIngredient
                        }
                    });
                }}>
                    <CheckCircle color={ingredient.owned ? "secondary" : "inherit"}/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
        <Divider/>
        {displayPossibleBottles &&
        <BottlesView ingredient={ingredient} close={() => setDisplayPossibleBottles(false)}/>}
    </React.Fragment>;
}