import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: "bold",
        marginLeft: "5px"
    }
}));


export default function AdvancedFilterBlock(props) {
    const classes = useStyles();

    return (
        <div className="advanced-filter-block">
            <div className="filter-title">
                {props.icon}
                <Typography className={classes.title}>
                    {props.title}
                </Typography>
            </div>
            <div className="flex-column-center">
                {props.children}
            </div>
        </div>
    );
}