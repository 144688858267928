import React, {Component} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./InfiniteScrollContainer.css";
import {CircularProgress} from "@material-ui/core";

class InfiniteScrollContainer extends Component {

    render() {
        return <div className={"scrollable-target"}>
            <InfiniteScroll dataLength={this.props.children.length}
                            next={this.props.fetchMoreMainData}
                            hasMore={this.props.recipesLength > (this.props.children.length)}
                            loader={<h4 className="loading-layout"><CircularProgress color="secondary"/></h4>}
            >
                {<div className="recipe-cards">
                    {this.props.children}
                </div>}
            </InfiniteScroll>
        </div>;
    }
}

export default InfiniteScrollContainer;