import React, {useState} from "react";
import DisplaySelectedIngredientRecipes from "./DisplaySelectedIngredientRecipes";
import RecipeAutoCompleteSearchField from "../../utilities/RecipeAutoCompleteSearchField";

export default function IngredientsRecipeView(props) {
    const [currentRecipe, setCurrentRecipe] = useState(null);
    return (
        <div className="flex-column-center">
            {<RecipeAutoCompleteSearchField setCurrentRecipe={setCurrentRecipe}/>}
            <DisplaySelectedIngredientRecipes currentRecipe={currentRecipe}/>
        </div>
    );
}