import React from "react";
import "./FullRecipe.css";
import Typography from "@material-ui/core/Typography";
import {useLocation} from "react-router-dom";
import {useQuery} from "@apollo/react-hooks";
import {GET_BOOK_NAME} from "../utilities/Queries";
import {client} from "../ApolloLayer";

export default function BookTitle(props) {
    let bookTitle = "";
    let location = useLocation();
    let pathName = location.pathname;
    let split = pathName.split("/");
    let bookAbbreviation = split[2];
    const {loading, error, data} = useQuery(GET_BOOK_NAME, {
        variables: {book: bookAbbreviation},
        client: client
    });
    if (loading || error) {
        return <div/>;
    }
    bookTitle = data.book.name;
    return <div className="book-title">
        <Typography variant="h5">{bookTitle}</Typography>
    </div>;
}