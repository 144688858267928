import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import "./FullRecipe.css";
import FullRecipeContent from "./FullRecipeContent";
import {Helmet} from "react-helmet";

export default function FullRecipe(props) {

    let location = useLocation();
    let pathName = location.pathname;
    let split = pathName.split("/");
    let bookFormattedName = split[2];
    let recipeNameWithoutSpaces = split[3];
    let getRecipeFullVariables = {
        bookFormattedName: bookFormattedName,
        nameWithoutSpaces: recipeNameWithoutSpaces
    };
    let headRecipeName = getRecipeNameFromFormatted(recipeNameWithoutSpaces);
    let headBookName = getBookNameFromFormatted(bookFormattedName);
    let title = headRecipeName + " - " + headBookName;
    useEffect(() => {
        // Update the document title using the browser API
        document.title = title;
    });
    return <div style={{width: "100%", minHeight: "calc(100vh - 66px)"}}>
        <Helmet>
            <title>{title}</title>
            <meta name="description"
                  content={"View the full recipe for " + headRecipeName + " from the " + headBookName + " cocktail book."} />
        </Helmet>
        <FullRecipeContent getRecipeFullVariables={getRecipeFullVariables} isLoggedIn={props.isLoggedIn} />
    </div>;
}

function getRecipeNameFromFormatted(recipeNameWithoutSpaces) {
    return recipeNameWithoutSpaces.split("_").join(" ").split("|x|").join("#");
}

function getBookNameFromFormatted(bookFormattedName) {
    if (bookFormattedName === "DeathCo") {
        return "Death & Co";
    }
    if (bookFormattedName === "SmugglersCove") {
        return "Smuggler's Cove";
    }
    if (bookFormattedName === "MinimalistTiki") {
        return "Minimalist Tiki";
    }
    if (bookFormattedName === "BeachbumBerryRemixed") {
        return "Beachbum Berry Remixed";
    }
    if (bookFormattedName === "SippinSafari") {
        return "Sippin' Safari";
    }
    if (bookFormattedName === "Tiki-ModernTropicalCocktails") {
        return "Tiki - Modern Tropical Cocktails";
    }
    if (bookFormattedName === "EasyTiki") {
        return "Easy Tiki"
    }
    if (bookFormattedName === "PotionsoftheCaribbean") {
        return "Potions of the Caribbean";
    }
    if (bookFormattedName === "CocktailCodex") {
        return "Cocktail Codex";
    }
    if (bookFormattedName === "MeehansBartenderManual") {
        return "Meehan's Bartender Manual";
    }
    if (bookFormattedName === "ImJustHerefortheDrinks") {
        return "I'm Just Here for the Drinks";
    }
    if (bookFormattedName === "SessionCocktails") {
        return "Session Cocktails";
    }
    if (bookFormattedName === "Imbibe") {
        return "Imbibe!";
    }
    if (bookFormattedName === "RegardingCocktails") {
        return "Regarding Cocktails";
    }
    if (bookFormattedName === "WinterDrinks") {
        return "Winter Drinks";
    }
    if (bookFormattedName === "TheNomadCocktailBook") {
        return "The Nomad Cocktail Book";
    }
    return bookFormattedName;
}