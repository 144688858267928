import React from "react";
import {Link, useLocation} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {client} from "../ApolloLayer";
import {
    ADD_MENU_ITEM,
    GET_COCKTAIL_MENU,
    SAVE_CATEGORY_DESCRIPTIONS,
    SAVE_DESCRIPTION,
    SAVE_MENU_ITEMS_ORDER,
    SAVE_TITLE
} from "./CocktailMenuQueries";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./CocktailMenu.css";
import CocktailMenuSortItem from "./CocktailMenuSortItem";
import List from "@material-ui/core/List";
import RootRef from "@material-ui/core/RootRef";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import QRCode from "react-qr-code";
import CocktailMenuRenderer from "./renderer/CocktailMenuRenderer";
import {Edit, Save} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import RecipeAutoCompleteSearchField from "../utilities/RecipeAutoCompleteSearchField";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

function onDragEnd(result, data, saveOrder) {
    let items = data.cocktailMenu.cocktailMenuItems;
    // dropped outside the list
    if (!result.destination) {
        return;
    }

    const newItems = reorder(
        items,
        result.source.index,
        result.destination.index
    );
    saveOrder({
        variables: {
            menuId: data.cocktailMenu.id,
            items: newItems.map((item) => {
                return item.id;
            })
        },
    });
}

export default function CocktailMenuEditor(props) {
    const [titleOpen, setTitleOpen] = React.useState(false);
    const [titleText, setTitleText] = React.useState("");
    const [descriptionOpen, setDescriptionOpen] = React.useState(false);
    const [descriptionText, setDescriptionText] = React.useState("");
    const [categoryOpen, setCategoryOpen] = React.useState(false);
    const [categoryState, setCategoryState] = React.useState(null);

    let location = useLocation();
    let pathName = location.pathname;
    let split = pathName.split("/");
    let cocktailMenuId = split[2];
    const {loading, error, data} = useQuery(GET_COCKTAIL_MENU, {
        variables: {id: cocktailMenuId},
        client: client
    });
    const [saveOrder] = useMutation(SAVE_MENU_ITEMS_ORDER, {
        client: client
    });
    const [addItem] = useMutation(ADD_MENU_ITEM, {
        client: client
    });
    const [saveTitle] = useMutation(SAVE_TITLE, {
        client: client
    });
    const [saveDescription] = useMutation(SAVE_DESCRIPTION, {
        client: client
    });
    const [saveCategory] = useMutation(SAVE_CATEGORY_DESCRIPTIONS, {
        client: client
    });
    if (loading) {
        return <CircularProgress color="secondary"/>;
    }
    if (error) {
        return error;
    }

    return <div className="flex-column-center">
        <div className="title-and-qr">
            <div className="title-and-description-outer">
                <div className="title-and-description-inner">
                    {renderTitle(data.cocktailMenu, titleOpen, setTitleOpen, titleText, setTitleText, saveTitle)}
                    {renderDescription(data.cocktailMenu, descriptionOpen, setDescriptionOpen, descriptionText, setDescriptionText, saveDescription)}
                    {renderCategoryDescriptions(data.cocktailMenu, categoryOpen, setCategoryOpen, categoryState, setCategoryState, saveCategory)}
                </div>
            </div>
            <div style={{marginRight: "10px"}}>
                <Link to={"/menu/" + cocktailMenuId} style={{textDecoration: "none", color: "inherit"}}>
                    <QRCode size={75} value={window.location.href.split("/#/")[0] + "/#/menu/" + cocktailMenuId}/>
                </Link>
            </div>
        </div>
        <div className="cocktail-items-outer-box">
            <div className="cocktail-menu-width">
                <div className="items-list">
                    <RecipeAutoCompleteSearchField setCurrentRecipe={(recipeId) => {
                        addItem({
                            variables: {
                                menuId: data.cocktailMenu.id,
                                id: recipeId
                            },
                        });
                    }}
                                                   excludedIds={data.cocktailMenu.cocktailMenuItems.map((item) => item.id.split(data.cocktailMenu.id + ":")[1])}
                    />
                    <DragDropContext onDragEnd={(result) => onDragEnd(result, data, saveOrder)}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => {
                                return <RootRef rootRef={provided.innerRef}>
                                    <List style={getListStyle(snapshot.isDraggingOver)}>
                                        {data.cocktailMenu.cocktailMenuItems.map((item, index) => {
                                            return <CocktailMenuSortItem key={index} item={item}
                                                                         menu={data.cocktailMenu} index={index}/>;
                                        })}
                                        {provided.placeholder}
                                    </List>
                                </RootRef>;
                            }}</Droppable>
                    </DragDropContext>
                </div>
                <CocktailMenuRenderer menu={data.cocktailMenu}/>
            </div>
        </div>
    </div>;
}

function renderTitle(cocktailMenu, titleOpen, setTitleOpen, titleText, setTitleText, saveTitle) {
    return renderText("title", cocktailMenu, titleOpen, setTitleOpen, titleText, setTitleText, saveTitle,
        <h3>{cocktailMenu.name}</h3>);
}

function renderDescription(cocktailMenu, descriptionOpen, setDescriptionOpen, descriptionText, setDescriptionText, saveDescription) {
    let renderedText = cocktailMenu.description ? <div>{cocktailMenu.description}</div> :
        <div style={{fontStyle: "italic"}}>{"No description added!"}</div>;
    return renderText("description", cocktailMenu, descriptionOpen, setDescriptionOpen, descriptionText, setDescriptionText, saveDescription,
        renderedText);
}

function renderCategoryDescriptions(cocktailMenu, categoryOpen, setCategoryOpen, categoryState, setCategoryState, saveCategory) {
    if (categoryState === null) {
        setCategoryState(cocktailMenu.categoryDescriptions.map((pair) => {
            return {
                category: pair.category,
                description: pair.description
            };
        }));
        return <div/>;
    }
    return <div>
        <Button color="secondary" onClick={() => setCategoryOpen(true)}>{"Describe categories"}</Button>
        <Dialog open={categoryOpen} onClose={() => {
            setCategoryOpen(false);
        }}>
            <DialogContent>
                {categoryState.map((categoryDescriptionPair, i) => {
                    return <div key={"catdesc" + i} className="flex-row-center">
                        <TextField color="secondary"
                                   style={{margin: "10px"}}
                                   label="Category"
                                   defaultValue={categoryDescriptionPair.category}
                                   onChange={(event) => {
                                       categoryDescriptionPair.category = event.target.value;
                                       setCategoryState(categoryState);
                                   }}
                                   variant="outlined"/>
                        <TextField color="secondary"
                                   style={{margin: "10px"}}
                                   label="Description"
                                   multiline
                                   defaultValue={categoryDescriptionPair.description}
                                   onChange={(event) => {
                                       categoryDescriptionPair.description = event.target.value;
                                       setCategoryState(categoryState);
                                   }}
                                   variant="outlined"/>
                    </div>;
                })}
                <Button color="secondary" onClick={() => {
                    let newCat = JSON.parse(JSON.stringify(categoryState));
                    newCat.push({category: "", description: ""});
                    setCategoryState(newCat);
                }}>{"Add more"}</Button>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => {
                    saveCategory({
                        variables: {
                            menuId: cocktailMenu.id,
                            categoryDescriptions: categoryState
                        }
                    });
                    setCategoryOpen(false);
                }}>{"Save"}</Button>
            </DialogActions>
        </Dialog>
    </div>;
}

function renderText(textType, cocktailMenu, open, setOpen, text, setText, saveText, renderedText) {
    let name = cocktailMenu.name;
    let description = cocktailMenu.description;
    let id = cocktailMenu.id;
    let isTitle = textType === "title";
    let isDescription = textType === "description";
    let key = isTitle ? "saveCocktailMenuTitle" : "saveCocktailMenuDescription";

    return <div className="flex-row-center full-width">
        {open ? <TextField color="secondary"
                           key={key}
                           defaultValue={text}
                           style={{width: "75%"}}
                           multiline={isDescription}
                           onChange={(event) => {
                               setText(event.target.value);
                           }}
        /> : renderedText}
        {open ? <IconButton
                color="secondary"
                aria-label="Edit"
                onClick={() => {
                    saveText({
                        variables: {
                            menuId: id,
                            text: text
                        }
                    });
                    setOpen(false);
                }}
            >
                <Save/>
            </IconButton> :
            <IconButton
                color="secondary"
                aria-label="Edit"
                onClick={() => {
                    setText(isTitle ? name : description);
                    setOpen(true);
                }}
            >
                <Edit/>
            </IconButton>
        }
    </div>;
}