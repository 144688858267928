import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {LOAD_SEARCH_TERMS} from "../utilities/Queries";
import RecipesOuter from "./RecipesOuter";
import RecipesInner from "./RecipesInner";
import {client} from "../ApolloLayer";


export default function Recipes(props) {
    const {loading, error, data: {searchTerms, selectedGlasses, selectedBuildTypes, selectedTypes, selectedCocktailCategories, selectedBooks, minMaxIngredients, minMaxABV, minMaxAlcoholicUnits}} = useQuery(LOAD_SEARCH_TERMS, {
        client: client
    });
    if (loading) {
        return <div/>;
    }
    if (error) {
        return error;
    }
    let key = selectedBuildTypes.data.reduce((acc, item) => {
            return acc + "-" + item;
        }, "")
        + selectedTypes.data.reduce((acc, item) => {
            return acc + "-" + item;
        }, "")
        + selectedGlasses.data.reduce((acc, item) => {
            return acc + "-" + item;
        }, "")
        + selectedBooks.data.reduce((acc, item) => {
            return acc + "-" + item;
        }, "")
        + selectedCocktailCategories.data.reduce((acc, item) => {
            return acc + "-" + item;
        }, "");
    if (props.isBook) {
        return <RecipesOuter key={key}
                             isLoggedIn={props.isLoggedIn}
                             searchTerms={searchTerms}
                             selectedGlasses={selectedGlasses.data}
                             selectedBuildTypes={selectedBuildTypes.data}
                             selectedTypes={selectedTypes.data}
                             selectedCocktailCategories={selectedCocktailCategories.data}
                             selectedBooks={selectedBooks.data}
                             minMaxIngredients={minMaxIngredients}
                             minMaxABV={minMaxABV}
                             minMaxAlcoholicUnits={minMaxAlcoholicUnits}/>;
    } else {
        return <RecipesInner key={key}
                             isLoggedIn={props.isLoggedIn}
                             searchTerms={searchTerms}
                             selectedGlasses={selectedGlasses.data}
                             selectedBuildTypes={selectedBuildTypes.data}
                             selectedTypes={selectedTypes.data}
                             selectedCocktailCategories={selectedCocktailCategories.data}
                             selectedBooks={selectedBooks.data}
                             minMaxIngredients={minMaxIngredients}
                             minMaxABV={minMaxABV}
                             minMaxAlcoholicUnits={minMaxAlcoholicUnits}/>;
    }

}