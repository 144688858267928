import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import IngredientsRecipeView from "./recipeView/IngredientsRecipeView";
import IngredientsIndividualView from "./individualView/IngredientsIndividualView";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: "10px"
    },
    description: {
        marginBottom: "10px"
    },
    or: {
        marginTop: "50px",
        marginBottom: "50px"
    },
    paper: {
        backgroundColor: "#f7f7f7",
        marginTop: "25px"
    }
}));

export default function Ingredients(props) {
    const isLargeScreen = useMediaQuery("(min-width:950px)");
    const {recipesView, individualView} = props;
    const classes = useStyles();

    let style = {height: "calc(100% - 25px)", paddingBottom: "20px"};
    if (isLargeScreen) {
        style.width = "960px";
    } else {
        style.width = "100%";
    }
    return (
        <div className="ingredients-outer">
            <Paper style={style} className={classes.paper} elevation={3}>
                {recipesView ? <IngredientsRecipeView/> :
                    individualView ? <IngredientsIndividualView/> :
                        renderChoices(isLargeScreen, classes)
                }
            </Paper>
        </div>
    );
}

function renderChoices(isLargeScreen, classes) {
    return <React.Fragment>
        <div className="flex-column-center margin-above margin-below">
            <div className={isLargeScreen ? "ingredients-you-have-a-choice-to-make" : "ingredients-you-have-a-choice-to-make-small"}>
                <Typography className={classes.title} variant="h5">
                    Add through recipes
                </Typography>
                <Typography className={classes.description} variant="body2">
                    You can add ingredients by searching for recipes you know you can make.
                    This makes it easy to add multiple ingredients.
                </Typography>
                <div className="flex-row-space-evenly" style={{width: "100%"}}>
                    <Link to={"/ingredients/recipes"} style={{textDecoration: "none"}}>
                        <Button variant="contained" color="secondary">Recipes</Button>
                    </Link>
                </div>
            </div>
            <div className={isLargeScreen ? "ingredients-you-have-a-choice-to-make" : "ingredients-you-have-a-choice-to-make-small"}>
                <Typography className={classes.title} variant="h5">
                    Add individually
                </Typography>
                <Typography className={classes.description} variant="body2">
                    Here, you are presented with a full list of ingredients, and can see the effects
                    of
                    adding these
                    to your collection. This option is great if you are considering what to purchase
                    next.
                </Typography>
                <div className="flex-row-space-evenly" style={{width: "100%"}}>
                    <Link to={"/ingredients/individually"} style={{textDecoration: "none"}}>
                        <Button variant="contained" color="secondary">Individually</Button>
                    </Link>
                </div>
            </div>
        </div>
    </React.Fragment>
}