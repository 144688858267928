import {useQuery} from "@apollo/react-hooks";
import {GET_ALL_RECIPES_FOR_INGREDIENTS_SEARCH} from "./Queries";
import {client} from "../ApolloLayer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    autoComplete: {
        width: "90%"
    }
}));

export default function RecipeAutoCompleteSearchField(props) {
    const [search, setSearch] = useState("");
    let setCurrentRecipe = props.setCurrentRecipe;
    const classes = useStyles();

    let options = [];
    const {loading, error, data, refetch} = useQuery(GET_ALL_RECIPES_FOR_INGREDIENTS_SEARCH, {
        variables: {search: null},
        fetchPolicy: "network-only",
        client: client
    });
    if (!loading && !error) {
        options = data.searchRecipes
            .filter(recipe => !props.excludedIds || !props.excludedIds.includes(recipe.id));
    }
    return (
        <Autocomplete
            inputValue={search}
            className={classes.autoComplete}
            options={options}
            groupBy={option => option.book.name}
            onChange={(event, value) => {
                setSearch("");
                if (value) {
                    setCurrentRecipe(value.id);
                }
            }}
            renderOption={option => option.name}
            getOptionSelected={((option, value) => {
                return option.id === value.id;
            })}
            freeSolo
            filterOptions={(options) => {
                return options;
            }}
            getOptionLabel={option => option.name}
            renderInput={params => {
                return <TextField
                    {...params}
                    label="Search for recipe"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    onChange={(event) => {
                        let search = event.target.value;
                        setSearch(search);
                        if (search === "") {
                            search = null;
                        }
                        refetch({search: search});
                    }}
                    InputProps={{
                        ...params.InputProps,
                        type: "search"
                    }}
                />;
            }}
        />
    );
}