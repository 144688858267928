import React from "react";
import AdvancedFilterBlock from "./AdvancedFilterBlock";
import {InfoOutlined} from "@material-ui/icons";
import FilterDivider from "./FilterDivider";
import SliderFilterComponent from "./SliderFilterComponent";
import ChipFilterComponent from "./ChipFilterComponent";

export const MIN_ABV = 0;
export const MAX_ABV = 40;
export const MIN_INGREDIENTS = 1;
export const MAX_INGREDIENTS = 10;
export const MIN_ALCOHOLIC_UNITS = 0.0;
export const MAX_ALCOHOLIC_UNITS = 5.0;

export default function AdvancedFilters(props) {
    const isBook = props.isBook;
    return (
        <div className="flex-column">
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Type">
                <ChipFilterComponent chips={props.types}
                                     selectedChips={props.selectedTypes}
                                     typeName="selectedTypes"/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            {!isBook && (
                <React.Fragment>
                    <AdvancedFilterBlock icon={<InfoOutlined/>} title="Books">
                        <ChipFilterComponent chips={props.books}
                                             selectedChips={props.selectedBooks}
                                             typeName="selectedBooks"/>
                    </AdvancedFilterBlock>
                    <FilterDivider/>
                </React.Fragment>
            )}
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Amount of ingredients">
                <SliderFilterComponent value={props.minMaxIngredients}
                                       cacheName={"minMaxIngredients"}
                                       min={MIN_INGREDIENTS}
                                       max={MAX_INGREDIENTS}
                                       marks={[
                                           {
                                               value: 1,
                                               label: "1",
                                           },
                                           {
                                               value: 5,
                                               label: "5",
                                           },
                                           {
                                               value: 10,
                                               label: "10+",
                                           }
                                       ]}/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Construction method">
                <ChipFilterComponent chips={props.buildTypes}
                                     selectedChips={props.selectedBuildTypes}
                                     typeName="selectedBuildTypes"/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Cocktail categories">
                <ChipFilterComponent chips={props.cocktailCategories}
                                     selectedChips={props.selectedCocktailCategories}
                                     typeName="selectedCocktailCategories"/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Alcohol by volume">
                <SliderFilterComponent value={props.minMaxABV}
                                       cacheName={"minMaxABV"}
                                       min={MIN_ABV}
                                       max={MAX_ABV}
                                       marks={[
                                           {
                                               value: 0,
                                               label: "0%"
                                           },
                                           {
                                               value: 7,
                                               label: "Spritz",
                                           },
                                           {
                                               value: 12,
                                               label: "Daiquiri",
                                           },
                                           {
                                               value: 20,
                                               label: "20%",
                                           },
                                           {
                                               value: 24,
                                               label: "Martini",
                                           },
                                           {
                                               value: 31,
                                               label: "Old Fashioned",
                                           },
                                           {
                                               value: 40,
                                               label: "40+",
                                           },
                                       ]}/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Glasses">
                <ChipFilterComponent chips={props.glasses}
                                     selectedChips={props.selectedGlasses}
                                     typeName="selectedGlasses"/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Alcoholic units">
                <SliderFilterComponent value={props.minMaxAlcoholicUnits}
                                       cacheName={"minMaxAlcoholicUnits"}
                                       min={MIN_ALCOHOLIC_UNITS}
                                       max={MAX_ALCOHOLIC_UNITS}
                                       step={0.1}
                                       marks={[
                                           {
                                               value: 0,
                                               label: "0",
                                           },
                                           {
                                               value: 5,
                                               label: "5+",
                                           },
                                       ]}/>
            </AdvancedFilterBlock>
        </div>
    );
}