import React from "react";
import "./FullRecipe.css";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import {renderComments, renderIngredients, renderInstructions, renderTags} from "./FullRecipeContent";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {combineLinkedRecipesWithThis, getLinkedRecipeTitle} from "./RecipesCard";

const useStyles = makeStyles(theme => ({
    card: {
        width: "250px"
    },
    ingredients: {
        marginBottom: "10px"
    },
    ingredientsExtras: {
        marginTop: "10px",
        marginBottom: "10px",
        marginLeft: "10px",
        fontStyle: "italic"
    },
    ingredientAmount: {
        width: "33%"
    },
    avatarPersonal: {
        backgroundColor: "inherit",
        color: "#d4af37"
    },
    chip: {
        margin: "5px"
    },
    commentsDialog: {
        width: "400px"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export default function RedesignFullRecipeLarge(props) {
    const recipe = props.recipe;
    const allTags = props.allTags;
    const classes = useStyles();

    let displayedRecipes = combineLinkedRecipesWithThis(recipe);
    const [value, setValue] = React.useState(0);

    return <div className="recipe-outer">
        <div className="recipe">
            {renderTabs(recipe, value, setValue, displayedRecipes, false)}
            {displayedRecipes.map((recipe, i) => (
                <div hidden={value !== i} key={i}>
                    <div className="flex-column-center">
                        <div className="title-and-book">
                            <Typography variant="h3" color="secondary">
                                {recipe.name}
                            </Typography>
                            <Link to={"/book/" + recipe.book.formattedName} style={{textDecoration: "none"}}>
                                <div className="flex-column-center">
                                    <Typography variant="h6" color="secondary">
                                        {recipe.book.name}
                                    </Typography>
                                    <Typography variant="h6" color="secondary">
                                        {"Page " + recipe.pageNumber + ""}
                                    </Typography>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="ingredients-and-instructions">
                        {renderIngredients(recipe, classes, false)}
                        <div className="vertical-divider"/>
                        <div>
                            {renderInstructions(recipe, classes)}
                            {renderComments(recipe, classes, props.saveComment, props.getRecipeFullVariables, false, props.isLoggedIn)}
                            {renderTags(recipe, props.deleteTag, props.addTag, props.getRecipeFullVariables, classes, allTags, false, props.isLoggedIn)}
                        </div>
                    </div>
                    <div className="facts">
                        {renderAbvCard(recipe, classes)}
                        {renderUnitsCard(recipe, classes)}
                        {renderCocktailCategoryCard(recipe, classes)}
                    </div>
                </div>
            ))}
        </div>
    </div>;
}

export function renderAbvCard(recipe, classes) {
    let abv = recipe.postDilutionAbv;
    let abvString = abv.toFixed(1) + "%";

    // We compare using the following recipes:
    // Pilsner 4.4%, Aperol spritz 6.7%, white wine 13%, red wine 14.5%, Margarita 15.5, Martini 23.8%, Old-fashioned 32%
    let innerDescription = "When served, the recipe has an ABV of " + abvString + ". ";
    if (abv < 0.5) {
        return undefined;
    } else if (abv < 4) {
        innerDescription += "That means it is weaker than a pilsner.";
    } else if (abv < 5) {
        innerDescription += "That puts it very close to a pilsner.";
    } else if (abv < 6.3) {
        innerDescription += "That puts it in between a pilsner and an Aperol Spritz.";
    } else if (abv < 7.5) {
        innerDescription += "That puts it very close to an Aperol Spritz.";
    } else if (abv < 11) {
        innerDescription += "That puts it in between an Aperol Spritz and white wine.";
    } else if (abv < 13.2) {
        innerDescription += "That puts it very close to white wine.";
    } else if (abv < 14.1) {
        innerDescription += "That puts it in between white and red wine.";
    } else if (abv < 15) {
        innerDescription += "That puts it very close to red wine.";
    } else if (abv < 21) {
        innerDescription += "That puts it in between red wine and a Martini.";
    } else if (abv < 25) {
        innerDescription += "That puts it close to a Martini.";
    } else if (abv < 30) {
        innerDescription += "That puts it in between a Martini and an Old-fashioned.";
    } else if (abv < 34) {
        innerDescription += "That puts it close to an Old-fashioned.";
    } else if (abv >= 34) {
        innerDescription += "That means it is stronger than an Old-fashioned.";
    }

    return <Card key={"abv-card"} className={classes.card}>
        <CardHeader
            title={abvString + " ABV"}
            subheader={"Alcohol by volume"}
        />
        <CardContent>
            <Typography variant="body2">
                {innerDescription}
            </Typography>
        </CardContent>
    </Card>;
}

export function renderUnitsCard(recipe, classes) {
    let units = recipe.alcoholicUnits;
    let unitsString = recipe.alcoholicUnits.toFixed(1);
    if (unitsString % 1 === 0) {
        unitsString = unitsString.split(".")[0];
    }
    if (unitsString === "1") {
        unitsString += " unit";
    } else {
        unitsString += " units";
    }

    // We compare using the following recipes:
    // Pilsner 4.4%, Aperol spritz 6.7%, white wine 13%, red wine 14.5%, Margarita 15.5, Martini 23.8%, Old-fashioned 32%
    let innerDescription = "This recipe contains " + unitsString + ". ";
    if (units < 0.5) {
        return undefined;
    } else if (units < 0.9) {
        innerDescription += "That means you'll get less drunk drinking this than a pilsner.";
    } else if (units < 1.2) {
        innerDescription += "You'll drink the same amount of alcohol as a pilsner.";
    } else if (units < 1.4) {
        innerDescription += "That puts it in between a pilsner and an Daiquiri.";
    } else if (units < 1.7) {
        innerDescription += "That puts it very close to a Daiquiri, a Whiskey Sour and an Old-fashioned.";
    } else if (units < 2.0) {
        innerDescription += "That puts it in between an Daiquiri and a Margarita.";
    } else if (units < 2.2) {
        innerDescription += "That puts it close to a Margarita.";
    } else if (units < 2.8) {
        innerDescription += "That puts it in between a Margarita and a Long Island Iced Tea.";
    } else if (units < 3.2) {
        innerDescription += "That puts it close to a Long Island Iced Tea.";
    } else if (units < 3.9) {
        innerDescription += "That puts it in between a Long Island Iced Tea and a Zombie.";
    } else if (units < 4.4) {
        innerDescription += "That puts it close to a Zombie. Most restaurants won't serve you more than two of these in an evening!";
    } else if (units >= 4.4) {
        innerDescription += "That means it is stronger than a Zombie!";
    }

    return <Card key={"units-card"} className={classes.card}>
        <CardHeader
            title={unitsString}
            subheader={"Alcoholic units"}
        />
        <CardContent>
            <Typography variant="body2">
                {innerDescription}
            </Typography>
        </CardContent>
    </Card>;
}

export function renderCocktailCategoryCard(recipe, classes) {
    let cocktailCategory = recipe.cocktailCategory;
    let cocktailCategoryName = recipe.cocktailCategory;
    if (cocktailCategoryName === "WhiskeyHighball") {
        cocktailCategoryName = "Whiskey Highball";
    }
    if (cocktailCategoryName === "OldFashioned") {
        cocktailCategoryName = "Old-fashioned";
    }

    let body = "This recipe is a variation on the " + cocktailCategoryName + ". ";
    if (cocktailCategory === "Daiquiri") {
        body += "These cocktails are very refreshing due to the amount of citrus in them.";
    } else if (cocktailCategory === "Sidecar") {
        body += "The cocktail is balanced by a significant amount of liqueur and citrus - usually added to enhance the qualities of the main spirit.";
    } else if (cocktailCategory === "WhiskeyHighball") {
        body += "These cocktails usually have a low ABV making them easy to drink, and usually consists of more liquid than other cocktaills.";
    } else if (cocktailCategory === "OldFashioned") {
        body += "The cocktail will promote the main spirit and enhance it with a small amount of sweetener and seasoning.";
    } else if (cocktailCategory === "Flip") {
        body += "The cocktail will be very rich in taste and will work perfectly as a nightcap.";
    } else if (cocktailCategory === "Martini") {
        body += "That means the cocktail will strike a delicious balance between a spirit and an aromatic component, usually a fortified wine such as vermouth.";
    }

    return <Card key={"cocktail-category-card"} className={classes.card}>
        <CardHeader
            title={cocktailCategoryName}
            subheader={"Cocktail category"}
        />
        <CardContent>
            <Typography variant="body2">
                {body}
            </Typography>
        </CardContent>
    </Card>;
}


export function renderTabs(recipe, value, setValue, displayedRecipes, isMobile) {
    if (recipe.linkedRecipes.length === 0) {
        return undefined;
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    let shouldBeScrollable = isMobile || recipe.linkedRecipes.length > 4;
    return (
        <Tabs variant={shouldBeScrollable ? "scrollable" : "standard"}
              scrollButtons="auto"
              textColor="secondary"
              value={value}
              style={{width: "100%"}}
              centered={!shouldBeScrollable}
              onChange={handleChange}>
            {displayedRecipes.map((displayedRecipe) => {
                return <Tab key={"linked-recipe-" + displayedRecipe.id}
                            label={getLinkedRecipeTitle(displayedRecipe, displayedRecipes) + (displayedRecipe.comment || displayedRecipe.tags.length > 0 ? " *" : "")}/>;
            })}
        </Tabs>);
}
