import React from "react";
import {Chip} from "@material-ui/core";
import {client} from "../../ApolloLayer";

export default function ChipFilterComponent(props) {
    const {chips, selectedChips, typeName} = props;
    return (
        <div className="filter-chips">
            {chips.map((chip) => {
                let isSelected = selectedChips.includes(chip);
                return <Chip
                    key={"chip-" + chip}
                    label={chip}
                    variant={isSelected ? "default" : "outlined"}
                    color="secondary"
                    style={isSelected ? {margin: "5px", color: "white"} : {margin: "5px"}}
                    onClick={() => {
                        let newBuildTypes = calculateSelectedChips(chip, selectedChips);
                        let data = {};
                        data[typeName] = {
                            __typename: typeName,
                            data: newBuildTypes,
                            key: Math.random()
                        };
                        client.writeData({
                            data: data
                        });
                    }}
                />;
            })}
        </div>);
}

function calculateSelectedChips(chip, selectedChips) {
    if (selectedChips.length === 0) {
        return [chip];
    }
    if (selectedChips.includes(chip)) {
        selectedChips.splice(selectedChips.indexOf(chip), 1);
    } else {
        selectedChips.push(chip);
    }
    return selectedChips;
}