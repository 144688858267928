import React from "react";
import "../CocktailMenu.css";

export default function CocktailMenuItem(props) {
    let item = props.item;

    return <div className="cocktail-menu-item-border" key={item.id}>
        <div className="cocktail-menu-item-content">
            <div className="cocktail-menu-item-title">{item.name}</div>
            {renderOptionalInformation(item)}
            <div className="cocktail-menu-item-ingredients">{item.ingredients.map((ingredient, i) => {
                let name = calculateIngredientName(ingredient);
                if (i > 0) {
                    return " | " + name;
                }
                return name;
            })}</div>
        </div>
    </div>;
}


function calculateIngredientName(ingredient) {
    let detailLevelLow = true;
    let detailLevelHigh = false;
    let name = ingredient.name;
    if (detailLevelHigh) {
        let index = name.indexOf(", ");
        if (index < 0) {
            return name;
        }
        return name.substr(index + 1, name.length - 1).trim() + " " + name.substr(0, index);
    }
    if (detailLevelLow) {
        let index = name.indexOf(", ");
        if (index < 0) {
            return name;
        }
        let generateFullName = (category, specificType) => {
            return specificType + " " + category;
        };
        let category = name.substr(0, index);
        let specificType = name.substr(index + 1, name.length - 1).trim();
        let lowerCaseCategory = category.toLowerCase();
        if (lowerCaseCategory === "rum") {
            // if (specificType === "Black Blended Overproof") {
            //     return generateFullName(category, "Overproof");
            // }
        } else if (lowerCaseCategory === "juice" || lowerCaseCategory === "syrup" || lowerCaseCategory === "nectar" ||
            lowerCaseCategory === "amaro" || lowerCaseCategory === "extract" || lowerCaseCategory === "fruit") {
            return specificType;
        } else if (lowerCaseCategory === "tequila" || lowerCaseCategory === "coconut" || lowerCaseCategory === "cream") {
            return category;
        } else if (lowerCaseCategory === "brandy") {
            if (specificType === "Cognac" || specificType === "VS Cognac" || specificType === "Pisco" || specificType === "Calvados") {
                return specificType;
            }
            if (specificType === "Grape") {
                return category;
            }
        } else if (lowerCaseCategory === "whiskey") {
            if (specificType === "Bourbon" || specificType === "Blended Scotch" || specificType === "Islay Scotch" || specificType === "Scotch") {
                return specificType;
            }
        } else if (lowerCaseCategory === "gin") {
            if (specificType === "London Dry") {
                return category;
            }
        } else if (lowerCaseCategory === "liqueur") {
            if (specificType === "Falernum" || specificType === "Luxardo Maraschino" ||
                specificType === "Green Chartreuse" || specificType === "Yellow Chartreuse" ||
                specificType === "Absinthe" ||specificType === "Bénédictine") {
                return specificType;
            }
        }
        return generateFullName(category, specificType);
    }
}

function renderOptionalInformation(item) {
    let abv = item.postDilutionAbv.toFixed(1) + " %";
    let volume = item.postDilutionVolume.toFixed(0) + " ml";

    return <div className="flex-row-center">{abv + " | " + volume}</div>;
}