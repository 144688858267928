import React, {Component} from "react";
import "./App.css";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import {HashRouter, Route, Switch} from "react-router-dom";
import CocktailMenuExternalUserFetcher from "./cocktailmenu/external/CocktailMenuExternalUserFetcher";
import ApolloLayer from "./ApolloLayer";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#FFF"
        },
        secondary: {
            main: "#d4af37"
        }
    },
});

class App extends Component {

    render() {
        return <HashRouter>
            <ThemeProvider theme={theme}>
                <Switch>
                    <Route path="/menu/:s">
                        <CocktailMenuExternalUserFetcher/>
                    </Route>
                    <Route path="/widget">
                        <iframe style={{width: "500px", height: "800px"}} src={"https://www.survey-xact.dk/engage/app/widget?name=FromPD&email=frompd@pd.dk&id=12311"}/>
                    </Route>
                    <Route path="*">
                        <ApolloLayer/>
                    </Route>
                </Switch>
            </ThemeProvider>
        </HashRouter>;
    }
}

export default App;
