import React from "react";
import {useQuery} from "@apollo/react-hooks";
import {GET_RECIPE_INGREDIENTS_BASIC_SUGGESTIONS} from "../../utilities/Queries";
import RecipesCard from "../../recipes/RecipesCard";
import {CircularProgress} from "@material-ui/core";
import {client} from "../../ApolloLayer";


export default function DisplaySelectedIngredientRecipesEmpty(props) {
    const {loading, error, data} = useQuery(GET_RECIPE_INGREDIENTS_BASIC_SUGGESTIONS, {
        fetchPolicy: "network-only",
        client: client
    });
    if (loading) {
        return <CircularProgress color="secondary"/>;
    }
    if (error) {
        return <div/>;
    }
    return <div className="recipe-cards">
        {data.ingredientsRecipeBasicSuggestions.map(recipe => {
            return <RecipesCard key={recipe.id} recipe={recipe} isVariant={false} isIngredientsView={true}/>;
        })}
    </div>;
}