import React, {Component} from "react";
import "./MainView.css";
import Recipes from "../recipes/Recipes";
import FilterMenu from "./search/FilterMenu";
import Paper from "@material-ui/core/Paper";
import BookTitle from "./BookTitle";
import {Helmet} from "react-helmet";

class MainView extends Component {
    render() {
        const isBook = this.props.isBook;
        return <Paper style={{backgroundColor: "#f7f7f7", maxWidth: "960px", overflowX: "hidden", marginTop: "10px"}}
                      elevation={3}>
            <Helmet>
                <title>{"Project Daiquiri"}</title>
                <meta name="description" content="Search through thousands of cocktail and punch recipes from the best bars in the world.
                Create your own recipes and make cocktail menus for your own bar."/>
            </Helmet>
            <FilterMenu isBook={isBook}
                        isLoggedIn={this.props.isLoggedIn}
                        forceUpdate={() => {
                            this.forceUpdate();
                        }}/>
            {isBook && <BookTitle/>}
            <Recipes isBook={isBook} isLoggedIn={this.props.isLoggedIn}/>
        </Paper>;
    }
}

export default MainView;