import React from "react";
import {DialogContent} from "@material-ui/core";
import {useQuery} from "@apollo/react-hooks";
import {LOAD_BOTTLES} from "../../utilities/Queries";
import {client} from "../../ApolloLayer";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import AppBar from "@material-ui/core/AppBar";

export default function BottlesView(props) {
    const isLargeScreen = useMediaQuery("(min-width:950px)");
    let ingredient = props.ingredient;
    const {loading, error, data} = useQuery(LOAD_BOTTLES, {
        variables: {id: ingredient.id},
        client: client
    });

    let content = renderContent(loading, error, data);

    return <Dialog open={true} fullScreen={!isLargeScreen} onClose={() => {
        props.close();
    }}>
        {!isLargeScreen && <AppBar color="secondary" style={{position: "relative"}}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => {
                    props.close();
                }} aria-label="close">
                    <Close color="primary" />
                </IconButton>
                <Typography color="primary" variant="h6">
                    {"Suggested bottles for " + ingredient.name}
                </Typography>
            </Toolbar>
        </AppBar>}
        <DialogContent>
            {content}
        </DialogContent>
    </Dialog>;
}

function renderContent(loading, error, data) {
    if (loading) {
        return <div className="flex-row-center">
            <CircularProgress color="secondary"/>
        </div>;
    }
    if (error) {
        return error;
    }
    return <div className="flex-column">
        {data.ingredient.possibleBottles.map((bottle) => {
            return <Typography key={bottle.id}
                               variant="body2">{bottle.name + " (used in " + bottle.recipes.length + " recipes)"}</Typography>;
        })}
    </div>;
}